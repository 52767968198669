@import url(https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css);
@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,700,500,600);
@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
.login-signin {
    align-items:center;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    /* height: 812px; */
    /* padding: 0px 58.7px;
    width: 390px; */
}

.first-time-login {
    align-items: flex-start;
    background-color: var(--light-purple);
    display: flex;
    flex-direction: column;
    height: 812px;
    padding: 157px 63px;
    width: 390px;
}



.loginPopup{
    margin: 45% auto !important; 
    width: 90% !important; 
    border-radius: 80px 0px 80px 80px !important;
  }
  
  .loginPopupTop{
    margin: 40% auto !important;  
    width: 80% !important;
    border-radius: 80px 0px 80px 80px !important;
  }
.Ambassador_AmbassadorMain__3-0Wp{
    background-color: #160133;
}
.Ambassador_AmbassadorMainNew__1emi5{
    background-color: #160133;
}

@media(max-width: 750px) {
    .Ambassador_stepContainer__3Eq3d{
        display: table;
        width: 100%;
        margin: 0px auto; 
    }
}
@media(min-width: 750px) {
    .Ambassador_stepContainer__3Eq3d{
        display: table;
        width: 40%;
        margin: 0px auto; 
    }
}
.Ambassador_tableCell__3Znjz{
    width: 0.5em;
    display: table-cell;
    position: relative;
    padding-top: 0px;
}
.Ambassador_step__2MQ2O{
    width: 4rem;
    height: 4rem;
    margin: 0px auto;
    flex-shrink: 0;
    border-radius: 100%;
    text-align: center;
    padding: 1px;
    font-size: 10px;
    color: rgb(255, 255, 255);
    display: block;
    border-color:#593EDA;
    fill: linear-gradient(180deg, #604EE5 0%, #4102B4 100%);
    background-image: url(/static/media/Ellipse.a0658300.svg);
}
.Ambassador_step__2MQ2O img{
    width:3em;
}
.Ambassador_stepConnecterNext__3orKc{
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid rgb(82, 14, 175);
    right: 0px;
    left: 42%;
    margin-left: 54px;
}
.Ambassador_stepConnecterPrev__2lkQ5{
    position: absolute;
    top: 36px;
    height: 1px;
    border-top: 1px solid rgb(82, 14, 175);
    left: 0px;
    right: 42%;
    margin-right: 54px;
}

.Ambassador_buttonText__2e75Z{
    display: block;
    text-decoration: none;
    background: linear-gradient(180deg, #FEFEFE 35.94%, #3C1ABA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    text-align: center;
    font-family: Poppins;
    font-size: 0.80rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.0275rem;

    /* padding-top: 1.5rem; */
}
.Ambassador_textsomechange__1EX7y span{
    font-size: 1.2em;
    color:#ffd02c;
    font-weight: 700;
}

.Ambassador_headerTitle__2TRgQ{
    text-align:center;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-transform: uppercase;
    margin-top:0.5em;
}



.Ambassador_subHeaderText__3Cop4{
    background: linear-gradient(180deg, #FEFEFE 35.94%, #3C1ABA 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 5;
    letter-spacing: 0.0275rem;        
}
.Ambassador_description__1Izy7{
    color: #FFF;
    font-family: Poppins;
    font-style: normal;

    
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
}
.Ambassador_footerText__1CSC6{
    
    text-align:center;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    margin-top:2.6em;
}

.Ambassador_footerAmb__2_vNH{
    color: white;
letter-spacing:2px;

  /*-webkit-text-fill-color: white;  Will override color (regardless of order) */
  /* background: -webkit-linear-gradient(180deg, rgba(243,236,60,1) 0%, rgba(212,176,92,1) 100%); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-stroke: 3px transparent; */
  /* text-transform: uppercase;   */
  /* text-shadow: 0px 1px 3px 0px #5B03FF; */
  /* font-family: 'Lilita One'; */
  text-align: center;
  font-size: 1.2rem;
}
.Ambassador_foreverText__bZuaP{
    color: #FEFEFE;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 725;
    line-height: 2.1875rem;
    letter-spacing: 0.02375rem;
    
}
.Ambassador_inviteButton__2DGEW{
    position:relative;
    margin-top:1.5em;
    padding-top:0.5em;
    padding-bottom:1.5em;
    padding-left:1em;
    padding-right:1em;
    flex-shrink: 0;
    border-radius: 0.625em !important;
    border-color:#543CD6;
    background: #543CD6 !important;
    bottom:0.2em;
    color: #FEFEFE;
    text-shadow: 0px 1px 3px #5B03FF;
    font-family: Poppins;
    font-size: 1.2em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.035em;
    
    text-transform: uppercase;
}
.Ambassador_inviteButton__2DGEW span{
    position: relative;
    top: 0.3em;
}
.Ambassador_shareBox__1PQIW{
    padding-top:1em;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 11.9rem;
    height: 3.76856rem;
    flex-shrink: 0;
    background-image: url(/static/media/tooltip.132c6288.svg);
}
.Ambassador_shareBox__1PQIW img,span{
    cursor:pointer;
}
.Ambassador_shareSubHeaderText__vNHMy{
   
    -webkit-background-clip: text;
   
            background-clip: text;
    font-family: Poppins;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.0275rem;
}
.Ambassador_footerAmb2__uU8Te{
    color: #e6d348;
letter-spacing:2px;

  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  background: -webkit-linear-gradient(180deg, rgba(243,236,60,1) 0%, rgba(212,176,92,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  /* text-transform: uppercase;   */
  text-shadow: 0px 1px 3px 0px #5B03FF;
  font-family: 'Lilita One';
  text-align: center;
  font-size: 1.2rem;
}


html[dir="rtl"] .offcanvas-start {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.offcanvas-start {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    right: 0;
    left: initial;
}

html[dir="rtl"] .offcanvas-start.show {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

@media only screen and (max-width: 979px) {
    .offcanvas-start {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    .offcanvas-start.show {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        left: 0;
        right: initial;
    }
}

@-webkit-keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
}

@keyframes bounce {
    0%, 100% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
    50% {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
    }
}

@-webkit-keyframes shine {
    0% {
        -webkit-transform: translateX(-30px) rotate(-25deg);
                transform: translateX(-30px) rotate(-25deg);
    }

    100% {
        -webkit-transform: translateX(250px) rotate(-25deg);
                transform: translateX(250px) rotate(-25deg);
    }
}

@keyframes shine {
    0% {
        -webkit-transform: translateX(-30px) rotate(-25deg);
                transform: translateX(-30px) rotate(-25deg);
    }

    100% {
        -webkit-transform: translateX(250px) rotate(-25deg);
                transform: translateX(250px) rotate(-25deg);
    }
}
.svg-button:hover .svg-path {
    stroke: white !important;
  }
  
.shine {
    position: relative;
    overflow: hidden;
    transition: all 100ms linear;
}

.shine2 {
    position: relative;
    overflow: hidden;
    transition: all 2000ms linear;
}

.shine:hover::after {
    content: '';
    display: block;
    width: 75px;
    height: 175%;
    background: rgba(45, 44, 60, 1);
    background: linear-gradient(90deg, rgba(45, 44, 60, 0) 0%, rgba(45, 44, 60, 1) 25%, rgba(45, 44, 60, 1) 50%, rgba(45, 44, 60, 1) 75%, rgba(45, 44, 60, 0) 100%);
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: 0;
    -webkit-animation: shine 200ms linear;
            animation: shine 200ms linear;
    -webkit-transform: translateX(250px) rotate(-25deg);
            transform: translateX(250px) rotate(-25deg);
}

.shine2:hover::after {
    content: '';
    display: block;
    width: 55px;
    height: 275%;
    background: rgba(45, 44, 60, 1);
    background: linear-gradient(90deg, rgba(45, 44, 60, 0) 0%, rgba(45, 44, 60, 1) 25%, rgba(45, 44, 60, 1) 50%, rgba(45, 44, 60, 1) 75%, rgba(45, 44, 60, 0) 100%);
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: 0;
    -webkit-animation: shine 500ms linear;
            animation: shine 500ms linear;
    -webkit-transform: translateX(250px) rotate(-25deg);
            transform: translateX(250px) rotate(-25deg);
}

.carousel-control-prev {
    left: 0;
    right: initial;
}

.carousel-control-next {
    right: 0;
    left: initial;
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
}

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}
.glow {
    
    border: none;
    /* width: 200px;
    height: 200px; */
    -webkit-animation: glow 5s ease-in-out  alternate;
    animation: glow 5s ease-in-out ;
  }
  strong {
    font-weight: bold;
}
.pwaPopup{
    z-index: 99999;
    position: fixed;
    bottom: 0px;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    color:#7767f7;
    text-align: center;
}
.pwaPopup button{
    width: 100px;
    border-radius: 50px;
    padding: 10px;
    border: none;
    background: #7767f7;
    color: white;
    margin:5px;
    margin-top: 15px;
}
@-webkit-keyframes glow {
    0% {
        border-width: 1px;
        border: solid 1px #6352e8;
      }
      25% {
        border-width: 0px;
        border: solid 1px white;
      }
      50% {
        border-width: 1px;
        border: solid 1px #6352e8;
      }
      75% {
        border-width: 0px;
        border: solid 1px white;
      }
      100% {
        border-width: 0px;
        border: solid 0px white;
        
      }
      
     
  }
@keyframes glow {
    0% {
        border-width: 1px;
        border: solid 1px #6352e8;
      }
      25% {
        border-width: 0px;
        border: solid 1px white;
      }
      50% {
        border-width: 1px;
        border: solid 1px #6352e8;
      }
      75% {
        border-width: 0px;
        border: solid 1px white;
      }
      100% {
        border-width: 0px;
        border: solid 0px white;
        
      }
      
     
  }
  .timerBorder{
      border:1px solid red;
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
            transform: rotate3d(0, 0, 1, 0deg);
    -webkit-animation: 2s borderSpin linear 4s forwards;
            animation: 2s borderSpin linear 4s forwards;
  }
  @-webkit-keyframes borderSpin {
    from {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
              transform: rotate3d(0, 0, 1, 0deg);
    }
    to {
      -webkit-transform: rotate3d(0, 0, 1, 20deg);
              transform: rotate3d(0, 0, 1, 20deg);
    }
  }
  @keyframes borderSpin {
    from {
      -webkit-transform: rotate3d(0, 0, 1, 0deg);
              transform: rotate3d(0, 0, 1, 0deg);
    }
    to {
      -webkit-transform: rotate3d(0, 0, 1, 20deg);
              transform: rotate3d(0, 0, 1, 20deg);
    }
  }
* {
    box-sizing: border-box;
}

:root {
    --black: #160133;
    --black-haze: #f7f7f7;
    --black-rock: rgba(22, 1, 51, 1);
    --black-rock-2: #130331;
    --blackcurrant: rgba(45, 44, 60, 1);
    --blue-violet: #6352e8;
    --electric-violet: rgba(87, 0, 234, 1);
    --chicago: #5c5c5c;
    --ebb: #e3e3e3;
    --white: #ffffff;
    --light-purple: #D4D0F3;
    --danger: #D80404;
    --success: #04D835;
    --dark-gray: #00000029;
    --pixie-powder: #d9d9d9;
    --yellow-orange: rgba(255, 172, 75, 1);
    --gunsmoke: rgba(134, 134, 134, 1);
    --moon-raker: rgba(212, 208, 243, 1);
    --concrete: rgba(242, 242, 242, 1);
    --color-d80404: #D80404;
    --color-d9d9d9: #D9D9D9;
    --color-04d835: #04D835;
    --color-000000: #000000;
    --color-d4d0f3: #D4D0F3;
    --color-6352e871: #6352E871;
    --color-00000099: #00000099;
    --color-868686: #868686;
    --color-e3e3e3: #E3E3E3;
    --color-f2f2f2: #F2F2F2;
    --color-6352e8: #6352E8;
    --color-160133: #160133;
    --color-ffffff: #FFFFFF;
    --color-5c5c5c: #5C5C5C;
    --color-120b2b: #120B2B;
    --color-23036a: #23036A;
    --color-2d2c3c: #2D2C3C;
    --color-e8526f: #E8526F;
    --color-00000061: #00000061;

    --font-size-s: 7px;
    --font-size-m: 10px;
    --font-size-l: 12px;
    --font-size-xl: 17px;
    --font-size-xxl: 20px;
    --font-size-xxxl: 24px;

    --font-family-poppins: "Poppins", Helvetica;
    --font-family-Poppins: "Poppins", Helvetica;

    /* Font/text values */
    --font-style-normal: normal;
    --font-weight-normal: normal;
    --font-weight-bold: bold;
    --font-size-8: 8px;
    --font-size-9: 9px;
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-17: 17px;
    --font-size-18: 18px;
    --font-size-22: 22px;
    --character-spacing-0: 0px;
    --line-spacing-10: 10px;
    --line-spacing-12: 12px;
    --line-spacing-13: 13px;
    --line-spacing-14: 14px;
    --line-spacing-16: 16px;
    --line-spacing-25: 25px;
    --text-transform-uppercase: uppercase;
    
        touch-action: pan-x pan-y;
        height: 100% ;
      
}
/* The modal */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); 
    
    /* Black w/ opacity */
  }

  .modal-backdrop.show{
    opacity: 0;
    z-index: 1;
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    top: 30%;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }
  .fullscreentext{
    color: #25145c;
    padding: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    text-transform: capitalize;
  }
  
/* Character Styles */
.character-style-1 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #E8526F;
    color: var(--color-e8526f);
}

.character-style-2 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 17px;
    font-size: var(--font-size-17);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #6352E8;
    color: var(--color-6352e8);
}

.character-style-3 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 8px;
    font-size: var(--font-size-8);
    line-height: 10px;
    line-height: var(--line-spacing-10);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #868686;
    color: var(--color-868686);
}

.character-style-4 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #2D2C3C;
    color: var(--color-2d2c3c);
}

.character-style-5 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #6352E8;
    color: var(--color-6352e8);
}

.character-style-6 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #6352E8;
    color: var(--color-6352e8);
}

.character-style-7 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 12px;
    line-height: var(--line-spacing-12);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #5C5C5C;
    color: var(--color-5c5c5c);
}

.character-style-8 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    font-size: 14px;
    font-size: var(--font-size-14);
    line-height: 25px;
    line-height: var(--line-spacing-25);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #6352E8;
    color: var(--color-6352e8);
    text-transform: uppercase;
    text-transform: var(--text-transform-uppercase);
}

.character-style-9 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 13px;
    font-size: var(--font-size-13);
    line-height: 16px;
    line-height: var(--line-spacing-16);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #23036A;
    color: var(--color-23036a);
}

.character-style-10 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 11px;
    font-size: var(--font-size-11);
    line-height: 13px;
    line-height: var(--line-spacing-13);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #00000061;
    color: var(--color-00000061);
}

.character-style-11 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    font-size: 11px;
    font-size: var(--font-size-11);
    line-height: 13px;
    line-height: var(--line-spacing-13);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #6352E8;
    color: var(--color-6352e8);
}

.character-style-12 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: normal;
    font-weight: var(--font-weight-normal);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 14px;
    line-height: var(--line-spacing-14);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #120B2B;
    color: var(--color-120b2b);
}

.character-style-13 {
    font-family: "Poppins", Helvetica;
    font-family: var(--font-family-poppins);
    font-style: normal;
    font-style: var(--font-style-normal);
    font-weight: bold;
    font-weight: var(--font-weight-bold);
    font-size: 12px;
    font-size: var(--font-size-12);
    line-height: 14px;
    line-height: var(--line-spacing-14);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #120B2B;
    color: var(--color-120b2b);
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: auto;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: var(--color-6352e8);
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}


body {
    background: #160133 0 0% no-repeat padding-box;
    background: var(--color-160133) 0 0% no-repeat padding-box;
}

body.single {
    background: #F2F2F2 0 0% no-repeat padding-box;
    background: var(--color-f2f2f2) 0 0% no-repeat padding-box;
}

body p, body h1, body h2, body h3, body h4, body h5, body h6 {
    margin-bottom: 0;
}

body .Toastify__toast-theme--light {
    background: #FFFFFF 0 0% no-repeat padding-box;
    background: var(--color-ffffff) 0 0% no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
}

body .Toastify__close-button--light {
    color: #6352E8 0 0% no-repeat padding-box;
    color: var(--color-6352e8) 0 0% no-repeat padding-box;
}

body .tooltip-inner {
    background: #160133 0 0% no-repeat padding-box;
    background: var(--color-160133) 0 0% no-repeat padding-box;
    border: 2px solid #6352E8;
    border: 2px solid var(--color-6352e8);
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0 20px 20px 20px;
    opacity: 1;
    font: normal normal normal 11px/13px "Poppins", Helvetica;
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-11)/var(--line-spacing-13) var(--font-family-poppins);
    letter-spacing: 0px;
    letter-spacing: var(--character-spacing-0);
    color: #FFFFFF;
    color: var(--color-ffffff);
    text-align: left;
}

body .tooltip-inner::before {
    top: 7px;
    left: -21px;
    border-width: 0;
    content: "ⓘ";
    color: #6352E8;
    color: var(--color-6352e8);
    position: absolute;
}
body .bs-tooltip-top .tooltip-arrow::before {
 display: none;
}

.tooltip-inner:empty {
    display: none;
}

.nav-tabs .nav-link[id|="admin-tabs"] {
    color: black;
    background: white;
    margin: 2px;
    opacity: 0.6;
}

.nav-tabs .nav-link.active[id|="admin-tabs"] {
    color: black;
    background: white;
    margin: 2px;
    opacity:1;
}

p.firebase-emulator-warning + iframe {
    z-index: -1 !important;
    display: none;
}

#modal .Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}

.bg_main {
    background-color: #e3e3e3;
}
.dark_prpl {
    color: #160133;
}
.display4 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}
.display3 {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}
.titles {
    font-size: 20px;
    color: white;
    text-align: center;
}
/*coin-votes card*/
.coin_card {
    width: 116px;
    height: 200px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}
.coin_name {
    font-size: 13px;
    line-height: 13px;
    color: #160133;
}
.coin_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}
.coin_value {
    font-size: 13px;
    color: #160133;
}
.coin_votes {
    font-size: 11px;
    color: #e3e3e3;
}
.coin_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}
/*pair-votes card*/
.pair_card {
    width: 236px;
    height: 126px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}

.pair_name {
    font-size: 13px;
    line-height:10px;
    font-weight: 700;
    color: #160133;
}
.pair_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}
.pair_value {
    font-size: 13px;
    color: #160133;
}
.pair_votes {
    font-size: 11px;
    color: #e3e3e3;
}
.pair_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}
/* user card */
.user_card {
    width: 321px;
    height: 76px;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}
.sm_txt {
    font-size: 10px;
    line-height: 15px;
    color: #666;
}
.dark_prpl_bkgnd {
    background-color: #160133;
    color: #D4D0F3;
    box-shadow: 1px 1px 4px #6352E8;
}
.light_prpl_bkgnd {
    background-color: #D4D0F3;
    color: #160133;
    box-shadow: 1px 1px 4px #6352E8;
}
.profile_top {
    width: 390px;
    height: 163px;
    border-radius: 0 0 87px 0;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    background: linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
}
.profile_nav {
    width: 390px;
    height: auto;
    background-color: #D4D0F3;
    line-height: 115px;
}
.profile_nav_button {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: white;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}

.profile_nav_button_active {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}


.profile_nav_button:hover {
    background-color: #D4D0F3;
}

.profile_nav_icon {
    width: 40%;
    height: auto;
}
.username {
    font-size: 16px;
    line-height: 25px;
}
.username_sm {
    font-size: 12px;
}
.badge {
    background-color: #D4D0F3;
    font-weight: lighter;
    color: #6352E8;
    font-size: 10px;
    text-transform: uppercase;
    width: 90px;
    text-align: center;
}
.badge_sm {
    background-color: #6352E8;
    color: #D4D0F3;
    font-size: 8px;
    text-transform: uppercase;
    width: 70px;
    height: 14px;
    line-height: 14px;
    text-align: center;
}
.avatar {
    width: 66px;
    height: 66px;
}
.avatar_sm {
    width: 44px;
    height: 44px;
}
.margine {
    margin-left: 30px;
}
.cp_balance {
    border-radius: 6px;
    width: 212px;
    height: 136px;
    text-align: center;
    text-transform: uppercase;
}
.cp_PAX {
    font-size: 20px;
    line-height: 22px;
}
.cp_Value {
    font-size: 22px;
    line-height: 18px;
}
.cp_wallet {
    font-size: 9px;
    text-transform: none;
}
.cp_level {
    border-radius: 6px;
    width: 204px;
    padding: 3px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
}
.box_title {
    font-size: 14px;
    font-weight: normal;
}
.pool_box {
    width: 322px;
    height: 76px;
    border-radius: 6px;
    background-color: #6352E8;
    color: white;
    line-height: 76px;
}
.pool_box_info {
    width: 322px;
    height: 40px;
    border-radius: 6px;
    background-color: #160133;
    font-size: 19px;
    line-height: 40px;
}
.text_prpl {
    color: #6352E8;
}
.icon_size {
    width: 18px;
    height: 17px;
    line-height: 17px;
    text-align: center;
}
#circle {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}
#circle:hover {
    width: 28px;
    height: 28px;
    background: #D4D0F3;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}
/* POPUP */
.popup_card {
    width: 322px;
    height: auto;
    background-color: white;
    border-radius: 6px;
}
.popup_card_tex {
    text-align: center;
    font-size: 18px;
    color: #6352E8;
    vertical-align: middle;
    line-height: 18px;
}
.circle {
    width: 75px;
    height: 75px;
    border-radius: 75%;
    border: #6352E8 solid 1px;
}


/* PROFILE VOTE */

.profile_coin_vote {
    width:350px;
    height: 90px;
    border-radius: 6px;
    background: white;
}

.profile_coin_vote_txt {
    font-size: 13px;
    line-height: 16px;
    color: #6352E8;
    text-align: left;

}

.coin_vote_timer{
    font-size: 18px;
    line-height: 18px;
    color: #6352E8;
}


.coin_vote_icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e3e3e3;
}


.profile_pair_vote {
    width:350px;
    height:152px;
    border-radius: 6px;
    background: white;
    vertical-align: middle;
}

.pair_vote_vs{font-size:14px; color: #6352E8;}


.text-success{color:limegreen !important}
.text-danger{color:red!important}



.bg_main {
    background-color: #e3e3e3;
}

.display4 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}
.display3 {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}
.titles {
    font-size: 20px;
    color: white;
    text-align: center;
}
/*coin-votes card*/
.coin_card {
    width: 116px;
    height: 200px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}
.coin_name {
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    color: #160133;
}
.coin_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}
.coin_value {
    font-size: 13px;
    color: #160133;
}
.coin_votes {
    font-size: 11px;
    color: #e3e3e3;
}
.coin_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}
/*pair-votes card*/
.pair_card {
    width: 236px;
    height: 126px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}
.pair_name {
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    color: #160133;
}
.pair_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}
.pair_value {
    font-size: 13px;
    color: #160133;
}
.pair_votes {
    font-size: 11px;
    color: #e3e3e3;
}
.pair_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}
/* user card */
.user_card {
    width: 321px;
    height: 76px;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}
.sm_txt {
    font-size: 10px;
    line-height: 15px;
    color: #666;
}
.dark_prpl_bkgnd {
    background-color: #160133;
    color: #D4D0F3;
    box-shadow: 1px 1px 4px #6352E8;
}
.light_prpl_bkgnd {
    background-color: #D4D0F3;
    color: #160133;
    box-shadow: 1px 1px 4px #6352E8;
}
.profile_top {
    width: 390px;
    height: 163px;
    border-radius: 0 0 87px 0;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    background: linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
}
.profile_nav {
    width: 390px;
    height: auto;
    background-color: #D4D0F3;
    line-height: 115px;
}
.profile_nav_button {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: white;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}
.profile_nav_button:hover {
    background-color: #D4D0F3;
}
.profile_nav_button:active {
    background-color: #6352E8;
    color: #fff;
}
.profile_nav_icon {
    width: 40%;
    height: auto;
}
.username {
    font-size: 16px;
    line-height: 25px;
}
.username_sm {
    font-size: 12px;
}
.badge {
    background-color: #D4D0F3;
    font-weight: lighter;
    color: #6352E8;
    font-size: 10px;
    text-transform: uppercase;
    width: 90px;
    text-align: center;
}

.badge_sm {
    background-color: #6352E8;
    color: #D4D0F3;
    font-size: 8px;
    text-transform: uppercase;
    width: 70px;
    height: 14px;
    line-height: 14px;
    text-align: center;
}
.avatar {
    width: 66px;
    height: 66px;
}
.avatar_sm {
    width: 44px;
    height: 44px;
}
.margine {
    margin-left: 30px;
}
.cp_balance {
    border-radius: 6px;
    width: 204px;
    height: 136px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}
.cp_PAX {
    font-size: 18px;
    line-height: 10px;
    font-weight: 300;
}
.cp_Value {
    font-size: 22px;
    font-weight: bold;
}
.cp_wallet {
    font-size: 9px;
    text-transform: none;
}
.cp_level {
    border-radius: 6px;
    width: 204px;
    padding: 3px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
}
.box_title {
    font-size: 14px;
    font-weight: normal;
}
.pool_box {
    width: 322px;
    height: 76px;
    border-radius: 6px;
    background-color: #6352E8;
    color: white;
    line-height: 76px;
}
.pool_box_info {
    width: 322px;
    height: 40px;
    border-radius: 6px;
    background-color: #160133;
    font-size: 19px;
    line-height: 40px;
}
.text_prpl {
    color: #6352E8;
}
.icon_size {
    width: 18px;
    height: 17px;
    vertical-align: middle;

}
#circle {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}


#circle:hover {
    width: 28px;
    height: 28px;
    background: #D4D0F3;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}

.Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
}

html .accordion-button {
    text-align: left;
}

html[dir="rtl"] .accordion-button {
    text-align: right;
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: tada 2s ease 0s 3 normal forwards;
            animation: tada 2s ease 0s 3 normal forwards;
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.blink {
    -webkit-animation: myAnim 2s ease 0s infinite normal forwards;
            animation: myAnim 2s ease 0s infinite normal forwards;
    text-shadow: 1px 1px 2px black;
}

@-webkit-keyframes myAnim {
    0% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 1;
        -webkit-transform: translateY(-45px);
                transform: translateY(-45px);
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
    }
    65% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-12px);
                transform: translateY(-12px);
    }
    82% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }
    93% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }
    25%, 55%, 75%, 87% {
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    100% {
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

@keyframes myAnim {
    0% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        opacity: 1;
        -webkit-transform: translateY(-45px);
                transform: translateY(-45px);
    }
    24% {
        opacity: 1;
    }
    40% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-24px);
                transform: translateY(-24px);
    }
    65% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-12px);
                transform: translateY(-12px);
    }
    82% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-6px);
                transform: translateY(-6px);
    }
    93% {
        -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        -webkit-transform: translateY(-4px);
                transform: translateY(-4px);
    }
    25%, 55%, 75%, 87% {
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
    100% {
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        opacity: 1;
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
    }
}

.confetti-button {
    position: relative;
    transition: box-shadow ease-in 0.25s, -webkit-transform ease-in 0.1s;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, -webkit-transform ease-in 0.1s;
}

.confetti-button:focus {
    outline: 0;
}

.confetti-button:before, .confetti-button:after {
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
}

.confetti-button:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 20%, #6352E8 20%, transparent 30%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 10%, #6352E8 15%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.confetti-button:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 10%, #6352E8 15%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.confetti-button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.confetti-button.animate:before {
    display: block;
    -webkit-animation: topBubbles ease-in-out 0.75s forwards;
            animation: topBubbles ease-in-out 0.75s forwards;
}

.confetti-button.animate:after {
    display: block;
    -webkit-animation: bottomBubbles ease-in-out 0.75s forwards;
            animation: bottomBubbles ease-in-out 0.75s forwards;
}

@-webkit-keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@-webkit-keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@-webkit-keyframes bull_shake_left {
    0%, 100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
    }
    10% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    20%, 40%, 60% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
    }
    80% {
        -webkit-transform: rotate(-2deg);
                transform: rotate(-2deg);
    }
    90% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
}

@keyframes bull_shake_left {
    0%, 100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
    }
    10% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    20%, 40%, 60% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
    }
    80% {
        -webkit-transform: rotate(-2deg);
                transform: rotate(-2deg);
    }
    90% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
}

@-webkit-keyframes bull_shake_right {
    0%, 100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
    }
    10% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    20%, 40%, 60% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
    }
    80% {
        -webkit-transform: rotate(-2deg);
                transform: rotate(-2deg);
    }
    90% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
}

@keyframes bull_shake_right {
    0%, 100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 100% 50%;
                transform-origin: 100% 50%;
    }
    10% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    20%, 40%, 60% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }
    30%, 50%, 70% {
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
    }
    80% {
        -webkit-transform: rotate(-2deg);
                transform: rotate(-2deg);
    }
    90% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
}

